// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Swal from 'sweetalert2'
import Rails from '@rails/ujs'
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import grid_controller from "./grid_controller";

export default class extends grid_controller {

//   static targets = ["grid", "modal"];
    static targets = ["file"]

    initialize(){
        this.importData = [];
        this.columns = {
            'A': 'first_name',
            'B': 'last_name',
            'C': 'tier_id',
            'D': 'phone',
            'E': 'email',
            'F': 'date_of_birth',
            'G': 'address',
            'H': 'timezone'
        };
    }

    connect(){
        $('.import-excel-btn').hide();
        $('#excelErrorAlert').hide();
    }



    importExcel(e){
        console.log(this.data.get('importUrl'));
        let url = this.data.get('importUrl');

        $('#excelErrorAlert').hide();
        var data = this.importData;

        if(data.length > 0){
            Swal.fire({
                title: 'Import Clients',
                text: `Are you sure to import existing client data?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#347764',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                backdrop: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(
                        url,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "X-CSRF-Token": Rails.csrfToken()
                        },
                        body: JSON.stringify({rows: data})
                    }).then(async response => {
                        if (response.ok) {
                            const msg = await response.json();
    
                            $('#excelErrorAlert').removeClass("alert-success alert-warning border-0");
                            if(msg["status"] == 'success'){
                                $('#excelErrorAlert').addClass("alert-success");
                                $('.errorDetails').text(msg["message"]);
                                $('#excelErrorAlert').show();
                                this.clearGrid();
                            }else{
                                $('#excelErrorAlert').addClass("alert-warning border-0");
                                $('.errorDetails').text(msg["error_details"]);
                                $('#excelErrorAlert').show();
                            }
                        } else {
                            throw new Error("Things went poorly.");
                        }
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                          `Request failed: ${error}`
                        )
                        })
                  },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    // User clicked confirm button        

                }
            })
        }else{
            Swal.fire(
                'Excel Import',
                'Please upload excel file first.',
                'info'
            )
        }
    }


    renderExcel(data) {
        $('#myGrid').empty();
        new Grid({
            columns: [{
               id: 'first_name',
               name: 'First Name'
            }, {
               id: 'last_name',
               name: 'Last Name'
            }, {
               id: 'tier_id',
               name: 'Tier'
            },{
                id: 'phone',
                name: 'Phone'
            },{
                id: 'email',
                name: 'Email'
            },{
                id: 'date_of_birth',
                name: 'Date of Birth'
            },{
                id: 'address',
                name: 'Address'
            },{
                id: 'timezone',
                name: 'Timezone'
            }],
            fixedHeader: true,
            height: '800px',
            style: { 
                table: { 
                  'white-space': 'nowrap'
                }
              },
            data: data
        }).render(document.getElementById("myGrid")).forceRender();
    }

}
