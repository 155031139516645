// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

//   static targets = ["grid", "modal"];
    static targets = ["file"]

    initialize(){
        this.importData = [];
    }

    connect(){
        // $('.import-excel-btn').hide();
        // $('.export-csv-btn').hide();
        // $('#excelErrorAlert').hide();
    }

    displayGrid(){
        var fileUpload = this.fileTarget;
        console.log(fileUpload.files[0]);
        //Validate whether File is valid Excel file.
        var regex = /\.(xls[mx]?)$/;
        console.log(fileUpload.value.toLowerCase())
        if (regex.test(fileUpload.value.toLowerCase())) {

            if (typeof (FileReader) != "undefined") {
                var reader = new FileReader();

                //For Browsers other than IE.
                if (reader.readAsBinaryString) {
                    reader.onload = (e) => {
                        this.getTableFromExcel(e.target.result);
                        };
                    reader.readAsBinaryString(fileUpload.files[0]);
                }else {
                    //For IE Browser.
                    reader.onload = (e) => {
                        var data = "";
                        var bytes = new Uint8Array(e.target.result);
                        for (var i = 0; i < bytes.byteLength; i++) {
                                        data += String.fromCharCode(bytes[i]);
                        }
                        this.getTableFromExcel(data);
                    };
                    reader.readAsArrayBuffer(fileUpload.files[0]);
                }
            }else {
                alert("This browser does not support HTML5.");
            }

        } else {
            alert("Please upload a valid Excel file.");
        }
    }

    getTableFromExcel(data) {
        var workbook = XLSX.read(data, {
                type: 'binary'
        });

        var excelRows = [];

        var sheetName = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[sheetName];


        // start at the 2nd row - the first row are the headers
        var rowIndex = 2;

        // iterate over the worksheet pulling out the columns we're expecting
        while (worksheet['A' + rowIndex]) {
            var row = {};
            Object.keys(this.columns).forEach(column =>  {
                if(worksheet[column + rowIndex]){
                    row[this.columns[column]] = this.removeLineBreakInText(worksheet[column + rowIndex].w);
                }else{
                    row[this.columns[column]] = "";
                }
                row["excel_index"] = rowIndex;
            });

            excelRows.push(row);

            rowIndex++;
        }

        console.log(excelRows);
        if(excelRows.length > 0){
            $('.import-excel-btn').show();
            this.importData = excelRows;
            this.renderExcel(excelRows);    
        }
    }


    clearGrid(){
        $('#fileUpload').val('');
        $('#myGrid').empty();
        $('.import-excel-btn').hide();
        $('.export-csv-btn').hide();
        this.importData = [];
    }

    removeLineBreakInText(txt){
        if(txt){
            return txt.replace(/(?:\r\n|\r|\n)/g, '').trim();;
         }else {
             return "";
         }
    }
}
