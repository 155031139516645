// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Swal from 'sweetalert2'
import Rails from '@rails/ujs'
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import grid_controller from "./grid_controller";

export default class extends grid_controller {

//   static targets = ["grid", "modal"];
    static targets = ["file"]

    initialize(){
        this.importData = [];
        this.columns = {
            'A': 'start_time',
            'B': 'end_time',
            'C': 'first_name',
            'D': 'last_name',
            'E': 'phone',
            'F': 'client_email',
            'G': 'appointment_type',
            'H': 'calendar',
            'I': 'price',
            'J': 'is_paid',
            'K': 'amount_paid_online',
            'L': 'certificate_code', 
            'M': 'notes', 
            'N': 'scheduled_date', 
            'O': 'label', 
            'P': 'scheduled_by',
            'Q': 'cancelled',
            'R': 'acuity_appointment_id'
        };
    }

    connect(){
        $('.export-csv-btn').hide();
        $('.import-excel-btn').hide();
        $('#excelErrorAlert').hide();
    }
    
    async exportCsv(){
        let { value: invoice_number } = await Swal.fire({
            title: "Input Invoice Number",
            input: "number",
            inputPlaceholder: '1',
            inputValue: 1,
            inputAttributes: {
                min: 1
            },
            inputValidator: (value) => {
                if (value <= 0) {
                    return 'Invoice number must be greater than zero.'
                }
            },
            confirmButtonText: `Enter`,
        })
          
        if (invoice_number) {
            if(invoice_number > 0){
                let csvRandomString = $('.export-csv-btn').attr('id');
                let url = this.data.get('exportUrl');
                console.log(url + '?random_string=' + csvRandomString)
                window.location = url + '?random_string=' + csvRandomString+ '&invoice_number=' + invoice_number;
            }
        }
    }


    importExcel(e){
        console.log(this.data.get('importUrl'));
        let url = this.data.get('importUrl');

        $('#excelErrorAlert').hide();
        var data = this.importData;
        console.log(data);

        if(data.length > 0){
            Swal.fire({
                title: 'Import Appointments',
                text: `Are you sure to import appointments?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#347764',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                backdrop: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(
                        url,{
                        method: "POST",
                        context: this,
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "X-CSRF-Token": Rails.csrfToken()
                        },
                        body: JSON.stringify({rows: data})
                    }).then(async response => {

                        const msg = await response.json();

                        if (response.ok) {
                            $('#excelErrorAlert').removeClass("alert-success alert-warning border-0");
                            if(msg["status"] == 'success'){
                                // Show export csv button
                                $('.export-csv-btn').attr('id', msg["csv_random_string"]);
                                $('.export-csv-btn').show();

                                $('#excelErrorAlert').addClass("alert-success");
                                $('.errorDetails').text(msg["message"]);
                                $('#excelErrorAlert').show();
                                // this.clearGrid();
                            }else{
                                $('#excelErrorAlert').addClass("alert-warning border-0");
                                $('.errorDetails').text(msg["errors"]);
                                $('#excelErrorAlert').show();
                            }
                        }else{
                            throw new Error(response.statusText)
                        }
                        // return response.json()
                      })
                      .catch(error => {
                        Swal.showValidationMessage(
                          `Request failed: ${error}`
                        )
                        })
                  },
                allowOutsideClick: () => !Swal.isLoading()
            })
        }else{
            Swal.fire(
                'Excel Import',
                'Please upload excel file first.',
                'info'
            )
        }
    }


    renderExcel(data) {
        $('#myGrid').empty();
        new Grid({
            columns: [{
               id: 'start_time',
               name: 'Start Time'
            }, {
               id: 'end_time',
               name: 'End Time'
            }, {
               id: 'first_name',
               name: 'First Name'
            },{
                id: 'last_name',
                name: 'Last Name'
            },{
                id: 'phone',
                name: 'Phone'
            },{
                id: 'client_email',
                name: 'Email'
            },{
                id: 'appointment_type',
                name: 'Type'
            },{
                id: 'calendar',
                name: 'Calendar'
            },{
                id: 'price',
                name: 'Appointment Price'
            },{
                id: 'is_paid',
                name: 'Paid?'
            },{
                id: 'amount_paid_online',
                name: 'Amount Paid Online'
            },{
                id: 'certificate_code',
                name: 'Certificate Code'
            },{
                id: 'notes',
                name: 'Notes'
            },{
                id: 'scheduled_date',
                name: 'Date Scheduled'
            },{
                id: 'label',
                name: 'Label'
            },{
                id: 'scheduled_by',
                name: 'Scheduled By'
            },{
                id: 'cancelled',
                name: 'Canceled'
            },{
                id: 'acuity_appointment_id',
                name: 'Appointment ID'
            }],
            fixedHeader: true,
            height: '800px',
            style: { 
                table: { 
                  'white-space': 'nowrap'
                }
              },
            data: data
        }).render(document.getElementById("myGrid")).forceRender();
    }

}
