// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Swal from 'sweetalert2'
import Rails from '@rails/ujs'
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import { Controller } from "stimulus";

export default class extends Controller {


    initialize(){}

    connect(){}


    importCertificates(e){
        console.log(this.data.get('importUrl'));
        let url = this.data.get('importUrl');

        Swal.fire({
            title: 'Import Certificates',
            text: `Are you sure to import certificates from Acuity Server?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#347764',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            backdrop: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return fetch(
                    url,{
                    method: "POST",
                    context: this,
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-CSRF-Token": Rails.csrfToken()
                    }
                }).then(async response => {

                    const msg = await response.json();

                    if (response.ok) {
                        if(msg["status"] == 'success'){
                            $('#excelErrorAlert').addClass("alert-success");
                            $('.errorDetails').text(msg["message"]);
                            $('#excelErrorAlert').show();
                        }
                    }else{
                        throw new Error(response.statusText)
                    }
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                })
              },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Saved!', '', 'success');
                window.location = "/certificate_packages";
                // , function() {
                //     
                // });
            }
          })


    }




}


// Swal.fire({
//     title: 'Submit your Github username',
//     input: 'text',
//     inputAttributes: {
//       autocapitalize: 'off'
//     },
//     showCancelButton: true,
//     confirmButtonText: 'Look up',
//     showLoaderOnConfirm: true,
//     preConfirm: (login) => {
//       return fetch(`//api.github.com/users/${login}`)
//         .then(response => {
//           if (!response.ok) {
//             throw new Error(response.statusText)
//           }
//           return response.json()
//         })
//         .catch(error => {
//           Swal.showValidationMessage(
//             `Request failed: ${error}`
//           )
//         })
//     },
//     allowOutsideClick: () => !Swal.isLoading()
//   }).then((result) => {
//     if (result.isConfirmed) {
//       Swal.fire({
//         title: `${result.value.login}'s avatar`,
//         imageUrl: result.value.avatar_url
//       })
//     }
//   })
  